.member_current_list_icon {
    @apply w-5 h-5 fill-current text-gray-500;
}

.member_filter-form {
    @apply relative mx-auto mb-4 p-4 border border-gray-300 shadow-md;
    max-width: 45rem;
}

.member_view-item {
    @apply flex-1 mx-auto mt-8;
    @screen md {
        @apply max-w-3xl;
    }
    @screen lg {
        @apply max-w-1/2 mx-0;
    }
    &-wide {
        @screen lg {
            @apply max-w-3xl mx-auto;
        }
    }
}
