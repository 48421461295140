.content-wrap {
    // bg-white hides side menu bg color on mobile. Otherwise content is transparent
    @apply relative z-10 transition-all duration-150 ease-in-out bg-white;
    @screen lg {
        @apply z-auto ml-64;
    }
    // the following are for the admin menu when closed and then open
    .sidebar_nav-visible & {
        transform: translateX(10rem);
        @screen lg {
            transform: translateX(16rem);
        }
    }
    .sidebar_nav-submenu-open & {
        transform: translateX(20rem);
        @screen lg {
            transform: translateX(16rem);
        }
    }
}

.box {
    @apply p-4 border border-gray-400 shadow-md;
}
