.loading {
    @apply inline-block w-4 h-4 mr-2 bg-black rounded-full;
    animation: loading 1s infinite ease-in-out;
}

@keyframes loading {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
