/*stylelint-disable no-descending-specificity*/

// style all form controls/inputs
[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=number],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea {
    @apply w-full px-2 py-1 text-gray-800 border border-gray-300 rounded-sm bg-gray-100 transition-default outline-none;
    &:focus {
        @apply bg-gray-100;
    }
}
textarea {
    // fixes space below textareas, making it consistent across browsers
    @apply align-top text-black;
}
select {
    @apply w-full p-1 text-black border border-gray-300 rounded-sm bg-gray-100 transition-default outline-none;
    height: 2.14rem;
}

// make sure everything shows a pointer on hover
[type=checkbox], [type=radio], [type=submit], [type=image], label, select {
    @apply cursor-pointer;
}
[type=file] {
    @apply w-full;
}

[type=text],
[type=checkbox],
[type=radio],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=number],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
[type=file],
[type=image],
[type=submit],
textarea,
select {
    @apply outline-none rounded-sm;
    &:focus {
        @apply outline-none ring-2;
    }
}

.form-wrap {
    @apply w-11/12 max-w-lg mx-auto my-4 p-4 border border-gray-300 shadow-md;
}

.field-wrap {
    @apply mb-4;
    label {
        @apply block mb-1;
    }
}
// if no .field-help, then add .field-wrap to container along with these classes
.field-wrap-radio, .field-wrap-checkbox {
    @apply flex flex-nowrap items-start;
    input {
        @apply shrink;
        margin-top: 0.35em;
    }
    label {
        @apply grow mb-0 pl-2;
    }
}

.field-wrap-radios, .field-wrap-checkboxes {
    @apply mb-4;
    legend {
        @apply mb-1;
    }
    .field-wrap-radio, .field-wrap-checkbox {
        @apply mb-1 mr-4;
    }
}

.field-errors {
    @apply mb-1 pl-0 list-none;
    li {
        @apply mb-1 p-2 bg-gray-200 border-l-10 border-red-700;
    }
}

.field-wrap-readonly {
    @apply relative;

    &:after {
        @apply absolute right-0 text-xs opacity-50;
        top: 0.5rem;
        content: "read-only";
    }

    input {
        @apply opacity-50;
    }
}

// 100% width wraps help when within a flexbox (ex. for a checkbox).
.field-help {
    @apply w-full mt-px text-xs italic text-gray-600 leading-tight;
    // the padding helps it align better with field above
    padding-left: 0.1em;
}

// these show up after the form buttons, outside the form tag
// for extra actions, such as delete, that aren't part of the current form
// but also would add clutter to add them as links on the record list
// this is typically a <ul>
// the actions here may or may not be inside a form
.form-extra_actions {
    @apply flex flex-col flex-wrap items-end justify-end list-none;
}
.form-action {
    @apply text-sm ml-4;
}
