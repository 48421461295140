/* this file is for public and private/admin pages */
@import "media";

a, .button-link {
    @apply text-blue-500 text-left no-underline transition-default;
    &:hover, &:active {
        @apply underline text-blue-700;
    }
    &:focus {
        @apply ring-2 ring-blue-500 ring-offset-1 rounded-sm outline-none text-blue-700 underline;
    }
    &:disabled {
        @apply opacity-75;
        &:hover {
            @apply no-underline cursor-default text-blue-500;
        }
    }
}

a[href^=tel], a[href^=mailto] {
    @apply whitespace-nowrap;
}

b, strong {
    @apply font-bold;
}

// re-add margin to p as it's reset in tailwind's normalize
p {
    @apply my-4;
}

// re-add list styles as tailwind removes them
ul, ol {
    @apply pl-10;
}
ul {
    @apply list-disc;
}
ol {
    @apply list-decimal;
}

address {
    @apply not-italic;
}

td {
    @apply align-top;
}

.dev_warning {
    @apply py-2 text-center bg-orange-500 text-white border-b-orange-700;
}
