.sidebar_nav-wrap {
    @apply opacity-0 lg:opacity-100 fixed left-0 z-10 w-40 h-full text-white bg-gray-900 fill-current text-sm;
    @screen lg {
        @apply w-64 text-base;
    }
}

.sidebar_nav-visible .sidebar_nav-wrap {
    @apply opacity-100 transition-default;
}

.sidebar_nav-link {
    @apply block w-full text-gray-300 fill-current outline-none;
    &:hover {
        @apply text-white bg-gray-800 no-underline transition-none;
    }
    &:focus {
        @apply text-white ring-offset-gray-900 outline-none no-underline;
    }
}

.sidebar_nav-nav {
    @apply w-40 pt-3 pl-0 list-none;
    @screen lg {
        @apply w-64;
    }
    li {
        // so we can control the positioning of the submenu arrow
        @apply relative;
    }
}

.sidebar_nav-bottom_links {
    @apply text-inherit;
    &:hover {
        @apply no-underline text-white;
    }
    &:focus {
        @apply ring-offset-gray-900 no-underline text-white;
    }
}
