// reset text size and margins on headings as tailwind removes them
h1 {
    @apply text-3xl;
}
h2 {
    @apply text-2xl;
}
h3 {
    @apply text-xl;
}
h4 {
    @apply text-lg;
}
h5 {
    // uses base font size
}
h6 {
    @apply text-sm;
}
