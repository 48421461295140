.sidebar_nav-submenu-wrap {
    @apply fixed top-0 left-0 right-0 z-0 w-40 h-full opacity-0 invisible text-left bg-gray-800;
    margin-top: $header-height;
    @screen md {
        @apply w-64 mt-0;
    }
}
.sidebar_nav-submenu-wrap-open {
    @apply opacity-100 visible transition-opacity duration-300 ease-in;
    left: 10rem;
    @screen lg {
        left: 16rem;
    }
}
.sidebar_nav-submenu_header {
    @apply text-lg font-extralight border-b border-gray-600;
    min-height: $header-height;
    padding: 1.25rem 0 1rem 1rem;
    @screen lg {
        @apply text-2xl;
        padding: 0.7rem 0 0.7rem 1rem;
    }
}
