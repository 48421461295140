// transitions, classes applied by Vue
.md-enter-active,
.md-leave-active {
    @apply transition-all duration-500;
}
.md-enter,
.md-leave-active {
    @apply opacity-0;
    transform: translateY(24px);
}
