.header-wrap {
    @apply flex flex-col md:flex-row justify-between items-end md:items-center py-2 md:py-0 bg-gray-100 border-b border-gray-300;
    min-height: $header-height + 3.5;
    @screen xs {
        min-height: $header-height;
    }
}

.header-wrap-small {
    @apply flex lg:hidden justify-between items-center relative z-10 pt-4 text-white bg-black border-b border-gray-400;
    height: $header-height;

    a {
        @apply text-white;
    }
}

.header-page_title {
    @apply grow shrink-0 w-full md:w-10 px-4 text-xl md:text-3xl text-blue-600 font-headings font-extralight truncate;
}

// the order of the HTML elements should match how the elements
// appear on mobile (mobile first)
.header-actions {
    @apply flex flex-wrap justify-end items-center px-4 md:pb-0 text-right;
}

// for actions that should show as buttons
// this styling is similar to buttons
.header-action {
    @apply order-2 block ml-4 py-2 px-5 text-xs border border-blue-600 border-solid rounded transition-colors duration-150 ease-in-out hover:no-underline focus:no-underline hover:bg-white;
}

// for the main action(s)
.header-action-main {
    @apply order-3 my-2 text-sm text-center text-white hover:text-white focus:text-white bg-blue-500 hover:bg-blue-600 focus:bg-blue-600 border-blue-500 hover:border-blue-600 focus:rounded focus:ring-offset-inherit;

    @media (min-width: 880px) {
        @apply my-0;
    }
}

.header-secondary_actions {
    @apply order-1 ml-4 space-x-4 text-xs leading-loose;
    @media (min-width: 560px) {
        @apply leading-none;
    }
    a, button {
        @apply focus:py-1 focus:ring-offset-2 focus:ring-offset-gray-100 inline-block focus:ring-offset-gray-100 focus:no-underline;
    }
    a.header-action-main {
        @apply focus:py-2;
    }
}

.header-button_pair {
    @apply order-4 ml-4;
}
