.password-slide-enter, .password-slide-enter-to {
    @apply transition-all duration-500 ease-in-out;
}

.password-slide-enter {
    @apply h-0 opacity-0 -translate-y-6;
    > div {
        @apply h-0;
    }
}

.password-slide-enter-to {
    @apply opacity-100 translate-y-0;
    height: 1.65rem;
    > div {
        @apply h-auto;
    }
}

.password-slide {
    @apply relative z-10;
    margin-bottom: 0.15rem;
}
