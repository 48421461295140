.record_view-item_wrap {
    @apply max-w-3xl mt-4 mx-auto p-2 border-4 border-gray-300 rounded;
    &:first-child {
        @apply mt-0;
    }
}
.record_view-item_group_wrap {
    @apply mt-4 border-t-2 border-gray-400;
}
.record_view-item {
    @apply flex flex-wrap p-2 transition-colors duration-150 ease-in-out;
    &:hover {
        @apply bg-blue-100;
    }
}

.record_view-header_wrap {
    @apply flex justify-between px-2;
}
.record_view-header_actions {
    @apply ml-auto opacity-75 text-sm text-right;
    &:hover {
        @apply opacity-100;
    }
}

.record_view-item_label {
    @apply text-gray-500;
    min-width: 5rem;
    @screen md {
        @apply w-1/5;
    }
}

.record_view-item_value {
    @screen md {
        @apply w-4/5 pl-4;
    }
}

.record_view-icon {
    @apply w-5 h-5 align-text-top fill-current;
}

.data_view-grid {
    @apply grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 max-w-6xl;
}
.data_view-cell {
    @apply sm:col-span-1 text-gray-700 font-normal;
    &-flex {
        @apply flex items-center;
    }
}
.data_view-heading {
    @apply my-0 text-base font-medium text-slate-400;
}
