.event-schedule_grid {
    @apply grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mb-8;
}

.event-schedule-groups {
    @apply grid gap-4 pl-0 list-none;
    // this should result in a minimum of 2 columns and a maximum of 4 columns
    // from: https://stackoverflow.com/questions/52417889/setting-minimum-and-maximum-number-of-columns-using-css-grid
    grid-template-columns: repeat(auto-fit, minmax(0, min(100% / 2 - 1rem, max(8rem, 100% / 4 - 1rem))));
}
