.button {
    @apply py-2 px-4 text-white font-extralight bg-blue-500 text-center
        shadow-none border-2 border-blue-500 rounded tracking-wider
        transition-default outline-none;
    &:hover {
        @apply text-white bg-blue-800 border-blue-800 no-underline;
    }
    &:focus {
        @apply ring-2 ring-indigo-400 ring-offset-1 outline-none no-underline;
    }
    &:disabled {
        @apply bg-gray-400 border-gray-400 cursor-default;
        &:hover {
            @apply bg-gray-400 border-gray-400 cursor-default;
        }
    }
}
.button-critical {
    @apply text-red-600 bg-white border-red-600;
    &:hover {
        @apply bg-red-600 border-red-600;
    }
    &:focus {
        @apply border-red-600;
    }
    &:disabled {
        @apply bg-white border-gray-400;
        &:hover {
            @apply text-red-600 bg-white;
        }
    }
}

a.button {
    @apply inline-block;
    &:focus {
        @apply bg-blue-500;
    }
}
a.button-critical {
    &:focus {
        @apply bg-white;
    }
}
